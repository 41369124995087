<!-- RTO FBX 列表 -->
<template>
  <div 
	  :lang="$i18n.locale"
	  v-loading.fullscreen="uploadloading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" 
		class="app-container">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
		  <!-- RTO FBX 列表 显示合格SKU信息 -->
			<el-tab-pane :label="$t('rtoFbxOrderPage.tabOne')" name="first">
				<span slot="label"> 
					{{$t('rtoFbxOrderPage.tabOne')}} 
					<!-- <el-badge v-if="qcPassedCount > 0" :value="qcPassedCount"></el-badge> -->
				</span>
				<div class="listHeader">
					<i></i><span>{{$t('rtoFbxOrderPage.tabOnePageTitle')}}</span>
				</div>
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
				  <el-form-item>
				    <el-input type="textarea" v-model="passAwbNo" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageNo')" clearable />
				  </el-form-item>
					<el-form-item>
					  <el-input type="textarea" v-model="qcPassedSKU" :placeholder="$t('tipsInfo.inputPlaceholder') + 'SKU'" clearable />
					</el-form-item>
					<el-form-item>
						<el-select v-model="queryParams.instructionStatus"
							:placeholder="$t('rtoFbxOrderPage.selectStatusTips')"
							style="width:200px" :clearable="true">
							<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"
								></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-date-picker v-model="qcPassedCrossTime" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('rtoFbxOrderPage.crossCheckTimeStartTips')" :end-placeholder="$t('rtoFbxOrderPage.crossCheckTimeEndTips')" style="width:320px"
							></el-date-picker>
					</el-form-item>
					<!-- <el-form-item>
						<el-date-picker v-model="qcPassedInstructionTime" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('rtoFbxOrderPage.instructionTimeStartTips')" :end-placeholder="$t('rtoFbxOrderPage.instructionTimeEndTips')" style="width:300px"
							></el-date-picker>
					</el-form-item> -->
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button type="primary" icon="el-icon-refresh" @click="getList">{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}</el-button>
				  </el-form-item>
					<el-form-item>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.selectCartonTips')" placement="top-start">
						  <el-button type="primary" icon="el-icon-s-grid" @click="handleBatchOperation()">
						    {{$t('commonInfo.batchOperation')}}</el-button>
						</el-tooltip>
					</el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px)" v-loading="loading" 
				:row-key="getRowKey"
				ref="tableList"
				:data="dataList" @selection-change="changeSelection" border>
				  <el-table-column type="selection" :reserve-selection="true" align="center" />
				  <el-table-column prop="fbxAwbNo" :label="$t('commonInfo.packageNo')" align="center">
						<template slot="header">
							<span>{{$t('commonInfo.packageNo')}} 
							<el-tooltip class="item" effect="dark" :content="$t('tipsInfo.copyTips')" placement="top">
								<i class="el-icon-copy-document" style="cursor:pointer;"
								@click="handleCopyAwbNo('fbxAwbNo')"></i>
							</el-tooltip>
							</span>
						</template>
					</el-table-column>
				  <el-table-column prop="crosscheckTime" :label="$t('rtoFbxOrderPage.crossTime')" align="center" min-width="160"></el-table-column>
					<!-- <el-table-column prop="actionTypeStatusName" label="已选操作" align="center"></el-table-column> -->
					<el-table-column prop="actionTypeStatusName" :label="$t('commonInfo.statusColumn')" min-width="160" align="center">
						<template slot-scope="{ row }">
							<span>{{returnActionStatus(row,'0')}}</span>
							<!-- <i style="margin-left: 5px;color: #0086B3;" :class="returnActionStatus(row,2)"></i> -->
						</template>
					</el-table-column>
					<el-table-column :label="$t('rtoB2COrderPage.remainingDay')" align="center" width="140">
						<template slot-scope="{ row }">
							<span v-if="showStoreDayTips(row,'0') == 100"></span>
							<span v-else-if="showStoreDayTips(row,'0') > 0">{{showStoreDayTips(row,'0')}}</span>
							<span v-else>{{$t('rtoB2COrderPage.operationOverTime')}}</span>
							<!-- 存储时间超过25天提示 共30天 -->
							<el-tooltip v-if="showStoreDayTips(row,'0') <= 5" class="item" effect="dark"
								:content="$t('rtoB2COrderPage.operationTips')" placement="top-start">
								<i style="font-size: 22px; margin-left: 15px; color: #c7924c;" class="el-icon-warning"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="productList" label="SKU" min-width="140" align="center">
						<template slot-scope="{ row }">
							<div style="margin: 5px 0;" v-for="(item,index) in row.returnList" :key="index">
								<span v-if="item.type == 'pass'" class="qcPassSkuItem">{{item.sku + ' * '+ item.count}}</span>
								<el-tooltip v-if="item.type == 'failed'" class="item" effect="dark"
									:content="$t('rtoFbxOrderPage.qcFailedSkuTips')" placement="top-start">
									<span class="qcFailedSkuItem">{{item.sku + ' * '+ item.count}}</span>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="unpackPhoto" :label="$t('commonInfo.photo')" align="center" width="90">
						<template slot-scope="{ row }">
							<img v-if="row.unpackPhoto != null && row.unpackPhoto != '' && row.actionResult != null && row.actionResult != '100'" :src="row.unpackPhoto" @click="handleViewImg(row.unpackPhoto)" style="width: 60px;height: 60px;"/>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="actionStartTime" :label="$t('rtoFbxOrderPage.instructionTime')" align="center" min-width="160"></el-table-column> -->
					<el-table-column prop="actionEndTime" :label="$t('rtoFbxOrderPage.completeTime')" align="center" min-width="160"></el-table-column>
					<el-table-column :label="$t('commonInfo.operationColumn')" align="center" width="140px" fixed="right">
						<template slot-scope="{ row }">
							<el-button size="mini" type="text" icon="el-icon-document" @click="handleSkuInfo(row,'0')">{{$t('rtoFbxOrderPage.unpackingList')}}</el-button>
							<el-button style="margin-left: 0px;" v-if="row.unpackPhoto != null" size="mini" type="text" icon="el-icon-view" @click="handleViewImg(row.unpackPhoto)">{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleSizeChange"
				    @current-change="handleCurrentChange"
				    :current-page="queryParams.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="queryParams.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="total"
				  ></el-pagination>
				</div>
			</el-tab-pane>
			<!-- RTO FBX 列表 显示不合格商品 -->
			<el-tab-pane :label="$t('rtoFbxOrderPage.tabTwo')" name="second">
				<span slot="label">
					{{$t('rtoFbxOrderPage.tabTwo')}} 
					<!-- <el-badge v-if="qcFailedCount > 0" :value="qcFailedCount"></el-badge> -->
				</span>
				<div class="listHeader">
					<i></i><span>{{$t('rtoFbxOrderPage.tabTwoPageTitle')}}</span>
				</div>
				<el-form :model="failParams" ref="failForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
					<el-form-item>
					  <el-input type="textarea" v-model="failAwbNo" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageNo')" clearable />
					</el-form-item>
					<el-form-item>
					  <el-input type="textarea" v-model="qcFailedSKU" :placeholder="$t('tipsInfo.inputPlaceholder') + 'SKU'" clearable />
					</el-form-item>
					<el-form-item>
						<el-select v-model="failParams.instructionStatus"
							:placeholder="$t('rtoFbxOrderPage.selectStatusTips')"
							style="width:200px" :clearable="true">
							<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"
								></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-date-picker v-model="qcFailedCrossTime" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('rtoFbxOrderPage.crossCheckTimeStartTips')" :end-placeholder="$t('rtoFbxOrderPage.crossCheckTimeEndTips')" style="width:320px"
							></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-date-picker v-model="qcFailedInstructionTime" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('rtoFbxOrderPage.instructionTimeStartTips')" :end-placeholder="$t('rtoFbxOrderPage.instructionTimeEndTips')" style="width:320px"
							></el-date-picker>
					</el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleQueryFail">{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button type="primary" icon="el-icon-refresh" @click="getFailList">{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleResetFail">{{$t('commonInfo.dialogBtnReset')}}</el-button>
				  </el-form-item>
					
					<el-form-item>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.selectCartonTips')" placement="top-start">
						  <el-button type="primary" icon="el-icon-s-grid" @click="handleBatchFailOperation()">
						    {{$t('commonInfo.batchOperation')}}</el-button>
						</el-tooltip>
					</el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px)" v-loading="loading" 
				:row-key="getFailRowKey"
				ref="failedTableList"
				:data="failDataList" @selection-change="changeFailSelection" border>
				  <el-table-column type="selection" :reserve-selection="true" align="center" />
				  <el-table-column prop="fbxAwbNo" :label="$t('commonInfo.packageNo')" align="center">
						<template slot="header">
							<span>{{$t('commonInfo.packageNo')}} 
							<el-tooltip class="item" effect="dark" :content="$t('tipsInfo.copyTips')" placement="top">
								<i class="el-icon-copy-document" style="cursor:pointer;"
								@click="handleCopyAwbNo('fbxAwbNo')"></i>
							</el-tooltip>
							</span>
						</template>
					</el-table-column>
				  <el-table-column prop="crosscheckTime" :label="$t('rtoFbxOrderPage.crossTime')" align="center" min-width="160"></el-table-column>
					<!-- <el-table-column prop="actionTypeStatusName" label="已选操作" align="center"></el-table-column> -->
					<el-table-column prop="actionTypeStatusName" :label="$t('commonInfo.statusColumn')" min-width="140" align="center">
						<template slot-scope="{ row }">
							<span>{{returnActionStatus(row,'1')}}</span>
							<!-- <i style="margin-left: 5px;color: #0086B3;" :class="returnActionStatus(row,2)"></i> -->
						</template>
					</el-table-column>
					<el-table-column :label="$t('rtoB2COrderPage.remainingDay')" align="center" width="140">
						<template slot-scope="{ row }">
							<span v-if="showStoreDayTips(row,'1') == 100"></span>
							<span v-else-if="showStoreDayTips(row,'1') > 0">{{showStoreDayTips(row,'1')}}</span>
							<span v-else>{{$t('rtoB2COrderPage.operationOverTime')}}</span>
							<!-- 存储时间超过25天提示 共30天 -->
							<el-tooltip v-if="showStoreDayTips(row,'1') <= 5" class="item" effect="dark"
								:content="$t('rtoB2COrderPage.operationTips')" placement="top-start">
								<i style="font-size: 22px; margin-left: 15px; color: #c7924c;" class="el-icon-warning"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="productList" label="SKU" min-width="140" align="center">
						<template slot-scope="{ row }">
							<div style="margin: 5px 0;" v-for="(item,index) in row.returnList" :key="index">
								<span v-if="item.type == 'pass'" class="qcPassSkuItem">{{item.sku + ' * '+ item.count}}</span>
								<el-tooltip v-if="item.type == 'failed'" class="item" effect="dark"
									:content="$t('rtoFbxOrderPage.qcFailedSkuTips')" placement="top-start">
									<span class="qcFailedSkuItem">{{item.sku + ' * '+ item.count}}</span>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="unpackPhoto" :label="$t('commonInfo.photo')" align="center" width="90">
						<template slot-scope="{ row }">
							<img v-if="row.unpackPhoto != null && row.unpackPhoto != '' && row.actionResult != null && row.actionResult != '100'" :src="row.unpackPhoto" @click="handleViewImg(row.unpackPhoto)" style="width: 60px;height: 60px;"/>
						</template>
					</el-table-column>
					<el-table-column prop="qcFailActionStartTime" :label="$t('rtoFbxOrderPage.instructionTime')" align="center" min-width="160"></el-table-column>
					<el-table-column prop="qcFailActionEndTime" :label="$t('rtoFbxOrderPage.completeTime')" align="center" min-width="160"></el-table-column>
					<el-table-column :label="$t('commonInfo.operationColumn')" align="center" width="140px" fixed="right">
						<template slot-scope="{ row }">
							<el-button size="mini" type="text" icon="el-icon-document" @click="handleSkuInfo(row,'1')">{{$t('rtoFbxOrderPage.unpackingList')}}</el-button>
								<el-button style="margin-left: 0px;" v-if="row.unpackPhoto != null" size="mini" type="text" icon="el-icon-view" @click="handleViewImg(row.unpackPhoto)">{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleFailSizeChange"
				    @current-change="handleFailCurrentChange"
				    :current-page="failParams.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="failParams.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="failTotal"
				  ></el-pagination>
				</div>
			</el-tab-pane>
			<!-- RTO FBX 已完成任务列表 -->
			<el-tab-pane :label="$t('rtoFbxOrderPage.tabThree')" v-if="false" name="third">
				<el-form :model="alreadyQuery" ref="alQueryForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
				  <el-form-item label>
				    <el-input type="textarea" v-model="alAwbNoArr" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderNoColumn')" clearable />
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleAlQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				  </el-form-item>
				</el-form>
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="alLoading" :data="alDataList" border>
				  <el-table-column prop="fbxAwbNo" :label="$t('commonInfo.packageNo')" align="center">
						<template slot="header">
							<span>{{$t('commonInfo.packageNo')}} 
							<el-tooltip class="item" effect="dark" :content="$t('tipsInfo.copyTips')" placement="top">
								<i class="el-icon-copy-document" style="cursor:pointer;"
								@click="handleCopyOrderNo('fbxAwbNo')"
								></i>
							</el-tooltip>
							</span>
						</template>
					</el-table-column>
				  <el-table-column prop="initTime" :label="$t('rtoFbxOrderPage.crossTime')" align="center" width="200"></el-table-column>
				  <!-- <el-table-column prop="actionTypeStatusName" label="已选操作" align="center"></el-table-column> -->
				  <el-table-column prop="actionTypeStatusName" :label="$t('commonInfo.statusColumn')" align="center">
				  	<template slot-scope="{ row }">
				  		<span>{{returnActionStatus(row,1)}}</span>
				  		<!-- <i style="margin-left: 5px;color: #0086B3;" :class="returnActionStatus(row,2)"></i> -->
				  	</template>
				  </el-table-column>
					<el-table-column :label="$t('commonInfo.operationColumn')" align="center" width="140px">
						<template slot-scope="{ row }">
							<el-button size="mini" type="text" icon="el-icon-document" @click="handleSkuInfo(row,'3')">{{$t('rtoFbxOrderPage.unpackingList')}}</el-button>
							<el-button style="margin-left: 0px;" v-if="row.unpackPhoto != null && row.actionResult == '300' || row.actionResult == '500' || row.actionResult == '550'" size="mini" type="text" icon="el-icon-view" @click="handleViewImg(row.unpackPhoto)">{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleAlSizeChange"
				    @current-change="handleAlCurrentChange"
				    :current-page="alreadyQuery.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="alreadyQuery.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="alTotal"
				  ></el-pagination>
				</div>
			</el-tab-pane>
		</el-tabs>
		
		<el-image-viewer style="z-index: 9999;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		<!-- 客户查看及操作SKU信息 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.productInfo')" :visible.sync="skuInfoPopup" :close-on-click-modal="false"
		  :before-close="handleSkuDialogClose" width="1000px">
			<el-row v-if="clientOperation == 'edit'" style="margin: 15px 0;">
				<el-button type="primary" icon="el-icon-box" @click="handleStockAllQcPassed()">{{$t('rtoFbxOrderPage.stockAll')}}</el-button>
				<el-button type="danger" icon="el-icon-delete" @click="handleDestoryAllQcPassed()">{{$t('rtoFbxOrderPage.destoryAll')}}</el-button>
			</el-row>
		  <el-table :data="cartonSkuDetail" :loading="skuDetailLoading" ref="cartonSkuDetail"
		  :span-method="objectSpanMethod" :cell-style="cellStyle"
		  border style="width: 100%;">
		    <el-table-column :label="$t('commonInfo.packageNo')" prop="customerBatchNo" align="center" min-width="160"></el-table-column>
		    <el-table-column label="SKU" prop="clientProductCode" align="center" min-width="120"></el-table-column>
		    <el-table-column :label="$t('commonInfo.count')" prop="productCount" align="center">
		    </el-table-column>
		    <el-table-column :label="$t('commonInfo.action')" align="center">
					<template slot-scope="{ row }">
						<el-radio-group v-if="clientOperation == 'edit'" v-model="row.normalProductAction" size="mini">
						  <el-radio-button :label="$t('commonInfo.inventory')"></el-radio-button>
						  <el-radio-button :label="$t('commonInfo.destory')"></el-radio-button>
						</el-radio-group>
						<div v-if="clientOperation == 'check'">
							<span v-if="row.normalProductAction == '200'">{{$t('commonInfo.destory')}}</span>
							<span v-if="row.normalProductAction == '300'">{{$t('commonInfo.inventory')}}</span>
						</div>
					</template>
		    </el-table-column>
		  </el-table>
		  <el-footer style="line-height: 60px; text-align: right;">
				<el-button :disabled="submitLoading" @click="handleSkuDialogClose">{{clientOperation=='edit'?$t('commonInfo.dialogBtnCancel'):$t('commonInfo.dialogBtnClose')}}</el-button>
		    <el-button v-if="clientOperation == 'edit'" :disabled="submitLoading" :loading="submitLoading" type="primary" @click="handleSubmitClientAction('0')">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
		  </el-footer>
		</el-dialog>
		<!-- 不合格商品信息 -->
		<el-dialog :lang="$i18n.locale" :title="$t('rtoFbxOrderPage.qcFailedProductInfo')" :visible.sync="failSkuInfoPopup" :close-on-click-modal="false"
		  :before-close="handleSkuDialogClose" width="1300px">
		  <el-table :data="cartonSkuDetail" :loading="skuDetailLoading" class="qcFailTable" ref="cartonSkuDetail"
		  :span-method="objectSpanMethod" :cell-style="cellStyle"
		  border style="width: 100%;">
		    <el-table-column :label="$t('commonInfo.packageNo')" prop="customerBatchNo" align="center" width="160"></el-table-column>
		    <el-table-column label="SKU" prop="clientProductCode" align="center" width="120"></el-table-column>
		    <el-table-column label="SKU Damaged" align="center">
		    	<template slot="header">
		    		<span style="font-size: 18px;">{{$t('rtoFbxOrderPage.skuLabelDamage')}}</span>
						<el-row v-if="clientOperation == 'edit'">
							<span style="margin-right: 10px;">{{$t('commonInfo.batchOperation') + ':'}}</span>
							<el-button type="text" @click="handleChangeLabelAllQcFailed()">{{$t('rtoFbxOrderPage.changeSku')}}</el-button>
							<el-button type="text" @click="handleDestoryAllQcFailed('1')">{{$t('commonInfo.destory')}}</el-button>
						</el-row>
		    	</template>
		      <el-table-column :label="$t('commonInfo.count')" prop="skuDamagedCount" align="center">
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.photo')" prop="skuDamagedPicUrl" align="center">
		        <template slot-scope="{row}">
		          <img v-if="row.skuDamagedPicUrl != null && row.skuDamagedPicUrl != ''" :src="row.skuDamagedPicUrl" @click="handleViewImg(row.skuDamagedPicUrl)" style="width: 40px;height: 40px;"/>
		        </template>
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.action')"  align="center">
		    		<template slot-scope="{row}">
		    		  <el-radio-group v-if="row.skuDamagedCount != '' && clientOperation == 'edit'" v-model="row.skuDamagedAction" size="mini">
		    		    <el-radio-button :label="$t('rtoFbxOrderPage.changeSku')"></el-radio-button>
		    		    <el-radio-button :label="$t('commonInfo.destory')"></el-radio-button>
		    		  </el-radio-group>
							<div v-if="clientOperation == 'check'">
								<span v-if="row.skuDamagedAction == '200'">{{$t('commonInfo.destory')}}</span>
								<span v-if="row.skuDamagedAction == '600'">{{$t('rtoFbxOrderPage.changeSku')}}</span>
							</div>
		    		</template>
		      </el-table-column>
		    </el-table-column>
		    <el-table-column label="包裹损坏" align="center">
					<template slot="header">
						<span style="font-size: 18px;">{{$t('rtoFbxOrderPage.packageDamage')}}</span>
						<el-row v-if="clientOperation == 'edit'">
							<span style="margin-right: 10px;">{{$t('commonInfo.batchOperation') + ':'}}</span>
							<el-button type="text" @click="handleChangeAllPackage()">{{$t('rtoFbxOrderPage.changeOrigin')}}</el-button>
							<el-button type="text" @click="handleChangeAllOp()">{{$t('rtoFbxOrderPage.changePO')}}</el-button>
							<el-button type="text" @click="handleDestoryAllQcFailed('2')">{{$t('commonInfo.destory')}}</el-button>
						</el-row>
					</template>
		      <el-table-column :label="$t('commonInfo.count')" prop="damagedPackagingCount" align="center">
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.photo')" prop="damagedPackagingPicUrl" align="center">
		        <template slot-scope="{row}">
		          <img v-if="row.damagedPackagingPicUrl != null && row.damagedPackagingPicUrl != ''" :src="row.damagedPackagingPicUrl" @click="handleViewImg(row.damagedPackagingPicUrl)" style="width: 40px;height: 40px;"/>
		        </template>
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.action')" align="center">
		    		<template slot-scope="{row}">
		    		<el-radio-group v-if="row.damagedPackagingCount != '' && clientOperation == 'edit'" v-model="row.damagedPackagingAction" size="mini">
		    		  <el-radio-button :label="$t('rtoFbxOrderPage.changeOrigin')"></el-radio-button>
		    			<el-radio-button :label="$t('rtoFbxOrderPage.changePO')"></el-radio-button>
		    		  <el-radio-button :label="$t('commonInfo.destory')"></el-radio-button>
		    		</el-radio-group>
						<div v-if="clientOperation == 'check'">
							<span v-if="row.damagedPackagingAction == '200'">{{$t('commonInfo.destory')}}</span>
							<span v-if="row.damagedPackagingAction == '400'">{{$t('rtoFbxOrderPage.changeOrigin')}}</span>
							<span v-if="row.damagedPackagingAction == '500'">{{$t('rtoFbxOrderPage.changePO')}}</span>
						</div>
		    		</template>
		      </el-table-column>
		    </el-table-column>
		    <el-table-column label="产品损坏" align="center">
					<template slot="header">
						<span style="font-size: 18px;">{{$t('rtoFbxOrderPage.productDamage')}}</span>
						<el-row v-if="clientOperation == 'edit'">
							<span style="margin-right: 10px;">{{$t('commonInfo.batchOperation') + ':'}}</span>
							<el-button type="text" @click="handleDestoryAllQcFailed('3')">{{$t('commonInfo.destory')}}</el-button>
						</el-row>
					</template>
		      <el-table-column :label="$t('commonInfo.count')" prop="productDamageCount" align="center">
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.photo')" prop="productDamagePicUrl" align="center">
		        <template slot-scope="{row}">
		          <img v-if="row.productDamagePicUrl != null && row.productDamagePicUrl != ''" :src="row.productDamagePicUrl" @click="handleViewImg(row.productDamagePicUrl)" style="width: 40px;height: 40px;"/>
		        </template>
		      </el-table-column>
		      <el-table-column :label="$t('commonInfo.action')" align="center">
		    		<template slot-scope="{row}">
		    		<el-radio-group v-if="row.productDamageCount != '' && clientOperation == 'edit'" v-model="row.productDamageAction" size="mini">
		    		  <el-radio-button :label="$t('commonInfo.destory')"></el-radio-button>
		    		</el-radio-group>
						<div v-if="clientOperation == 'check'">
							<span v-if="row.productDamageAction == '200'">{{$t('commonInfo.destory')}}</span>
						</div>
		    		</template>
		      </el-table-column>
		    </el-table-column>
				<el-table-column :label="'SKU' + $t('rtoFbxOrderPage.label')" min-width="100" align="center">
					<template slot-scope="{row}">
						<singleUploadWithParams v-if="returnShowSKUUploadBtn(row)"
						v-model="skuLabelFileName" :stringParams="row.id.toString() + row.clientProductCode" :tipText="$t('rtoFbxOrderPage.uploadLabel')"
						@input="uploadQcFailedLabel"
						></singleUploadWithParams>
						<el-button v-if="clientOperation == 'check' && row.newSkuPicUrl != null"
						type="text" icon="el-icon-download" @click="handleDownloadLabel(row.newSkuPicUrl)"
						>{{$t('rtoFbxOrderPage.downloadLabel')}}</el-button>
					</template>
				</el-table-column>
		  </el-table>
		  <el-footer style="line-height: 60px; text-align: right;">
		  	<el-button :disabled="submitLoading" @click="handleSkuDialogClose">{{clientOperation == 'edit'?$t('commonInfo.dialogBtnCancel'):$t('commonInfo.dialogBtnClose')}}</el-button>
		    <el-button v-if="clientOperation == 'edit'" type="primary" :disabled="submitLoading" :loading="submitLoading" @click="handleSubmitClientAction('1')">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
		  </el-footer>
		</el-dialog>
		
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
	import {
		formatDate,
		transformTime
	} from "@/utils/index";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import singleUploadWithParams from "@/components/upload/singleUploadWithParams";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
		ElImageViewer,
		singleUploadWithParams
	},
  data() {
    //这里存放数据
    return {
			activeName:'first',
      queryParams: {
        page: 1,
        limit: 10,
				awbNoList:[],//箱号集合
				productQcType: '0',// 0合格商品，1不合格商品
				instructionStatus:'',//指令状态，300待处理 400处理中 500已完成
				crosscheckTimeStart:'',//开箱开始时间
				crosscheckTimeEnd:'',//开箱结束时间
				instructionTimeStart:'',//指令开始时间
				instructionTimeEnd:'',//指令结束时间
				productCodeList:[],//SKU编码
      },
			qcPassedCrossTime:null,// 合格开箱时间
			qcPassedInstructionTime:null,//合格指令时间
			qcPassedSKU:'',
			statusList:[
				{
					label:'待处理',
					value:'300',
				},
				{
					label:'处理中',
					value:'400',
				},
				{
					label:'已完成',
					value:'500',
				}
			],
			qcPassedCount:0,
			passAwbNo:'',
			loading:false,
			uploadloading:false,
      total: 0,
			dataList:[],
			// 勾选行
			selectionRow:[],
			ids:[],//翻页勾选记录
			// 订单操作
			fbxOrderNo:[],
			confirmLoading:false,
			
			// 查看图片
			imgList:[],
			showImg: false,
			//查看开箱验货后的SKU商品信息
			// 2022-6-6 zpy 更换开箱验货用户提交方式，只能一次性全部提交所有SKU 
			skuInfoLink:'',
			skuId:'',
			skuInfoAllData:[],
			skuFbxAwbNo:'',
			skuFbxActionResult:'',//选中订单的状态
			activeNameSub:'first',
			qcDataList:[],//合格数据
			qcSelectionItem:[],//合格选中的数据
			unQcDataList:[],//不合格数据
			unQcSelectionItem:[],//不合格选中的数据
			submitSkuActionLoading:false,
			skuQcLoading:false,
			isAllSelect:false,// 验证用户是否全部选择操作
			isClickSubmit:false,// 是否第一次点击提交按钮
			viewNoSkuPhotoLoading:false,//查看没有SKU产品的图片
			// 批量上架 2022-8-16
			multipleStockSkuLoading:false,
			
			// 查看商品信息
			skuInfoPopup:false,
			cartonSkuDetail:[],
			skuDetailLoading:false,
			
			// 第二个Tab,不合格商品列表
			failParams:{
				page:1,
				limit:10,
				awbNoList:[],//箱号集合
				productQcType: '1',// 0合格商品，1不合格商品
				instructionStatus:'',//指令状态，300待处理 400处理中 500已完成
				crosscheckTimeStart:'',//开箱开始时间
				crosscheckTimeEnd:'',//开箱结束时间
				instructionTimeStart:'',//指令开始时间
				instructionTimeEnd:'',//指令结束时间
				productCodeList:[],
			},
			failAwbNo:'',
			qcFailedSKU:'',
			qcFailedCrossTime:null,//不合格开箱时间
			qcFailedInstructionTime:null,//不合格指令时间
			qcFailedCount:0,//待处理数量
			failDataList:[],
			failTotal:0,
			failIds:[],
			failSelectionRow:[],
			
			// 批量操作
			failSkuInfoPopup:false,
			skuLabelFileName:'',
			submitLoading:false,
			
			clientOperation:'edit',// 记录操作类型，edit check
			
			
			// 第三个tab 已完成列表
			alreadyQuery:{
				page: 1,
				limit: 10,
				productQcType: '',// 0未操作操作中数据 1已完成任务
			},	
			alTotal:0,
			alAwbNoArr:'',
			alLoading:false,
			alDataList:[],
			downloadSkuLoading:false,
			
			customerName:'',
    };
  },
  //监听属性 类似于data概念
  computed: {
  	rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
  	  return {
				deliveryWarehouse: [{
					required: true,
					message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryWarehouse'),
					trigger: 'blur'
				}],
				deliveryAddress: [{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDest'),
					trigger: 'blur'
				}],
				contacts:[{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contactMan'),
					trigger: 'blur'
				}],
  		}
  	}
  },
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
		
		// 已完成分页条数
		handleAlSizeChange(size) {
		  this.alreadyQuery.limit = size;
		  this.alreadyQuery.page = 1;
		  this.getAlList();
		},
		// 已完成分页页数
		handleAlCurrentChange(page) {
		  this.alreadyQuery.page = page;
		  this.getAlList();
		},
		
		handleClick(tab, event){
		  if (tab.name == "first") {
		  	this.getList();
		  }else if(tab.name == 'second'){
				this.getFailList();
			} else if(tab.name == "third"){
		    this.getAlList();
		  }
		},
    async getList() {
			this.loading = true;
			this.dataList = [];
			
			const res = await this.$http.post("/toborder/customer/rto/fbx/newList", this.queryParams);
			if (res.code === 200) {
				this.qcPassedCount = 0;
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.total = res.data.totalCount;
				//去除勾选
				this.ids = [];
				this.selectionRow = [];
				this.$refs.tableList.clearSelection();
				data.forEach(item => {
					item.crosscheckTime = formatDate(item.crosscheckTime);
					if(item.actionTypeStatusName == '' || item.actionTypeStatusName == null){
						item.actionTypeStatusName = this.$t('tipsInfo.noData');
					}
					// 返回每个SKU信息列表 列表返回数据同一个SKU包含合格和不合格的数据，需要分离方便循环显示
					item.returnList = [];
					if(item.productList.length > 0){
						item.productList.forEach(subItem => {
							item.returnList.push({
								sku:subItem.clientProductCode,
								count:subItem.productCount,
								type:'pass',
								id:subItem.id + 'pass',
							})
						})
					}
					// 获取待处理数量
					if(item.normalProductInstructionStatus == ''){
						this.qcPassedCount ++;
					}
				})
				
			  this.loading = false;
			  this.dataList = data;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		// 获取唯一标识
		getRowKey(row){
		  return row.id;
		},
		//监听勾选
		changeSelection(selection) {
			this.ids = selection.map(item => item.id);
		  this.selectionRow = selection;
		},
		handleQuery(){
			this.queryParams.page = 1;
			if(this.passAwbNo != null && this.passAwbNo != ''){
				this.queryParams.awbNoList = this.strToArr(this.passAwbNo);
			}else{
				this.queryParams.awbNoList = [];
			}
			if(this.qcPassedCrossTime != null && this.qcPassedCrossTime != ''){
				this.queryParams.crosscheckTimeStart = this.qcPassedCrossTime[0];
				this.queryParams.crosscheckTimeEnd = this.qcPassedCrossTime[1];
			}else{
				this.queryParams.crosscheckTimeStart = '';
				this.queryParams.crosscheckTimeEnd = '';
			}
			
			if(this.qcPassedInstructionTime != null && this.qcPassedInstructionTime != ''){
				this.queryParams.instructionTimeStart = this.qcPassedInstructionTime[0];
				this.queryParams.instructionTimeEnd = this.qcPassedInstructionTime[1];
			}else{
				this.queryParams.instructionTimeStart = '';
				this.queryParams.instructionTimeEnd = '';
			}
			if(this.qcPassedSKU != ''){
				this.queryParams.productCodeList = this.strToArr(this.qcPassedSKU);
			}else{
				this.queryParams.productCodeList = [];
			}
			this.getList();
		},
		//重置
		handleReset(){
			this.qcPassedCrossTime = null;
			this.qcPassedInstructionTime = null;
			this.passAwbNo = '';
			this.qcPassedSKU = '';
			this.queryParams = {
				page: 1,
				limit: 10,
				awbNoList:[],//箱号集合
				productQcType: '0',// 0合格商品，1不合格商品
				instructionStatus:'',//指令状态，300待处理 400处理中 500已完成
				crosscheckTimeStart:'',//开箱开始时间
				crosscheckTimeEnd:'',//开箱结束时间
				instructionTimeStart:'',//指令开始时间
				instructionTimeEnd:'',//指令结束时间
				productCodeList:[],
			}
			this.getList();
		},
		// 批量操作 -- 合格产品
		handleBatchOperation(){
			if(this.selectionRow.length == 0){
				return this.$message.warning(this.$t('tipsInfo.selectCartonTips'))
			}
			// 判断是否都是等待指令产品
			let waitList = this.selectionRow.filter(item => item.normalProductInstructionStatus == '');
			if(waitList.length > 0){
				return this.$message.warning(this.$t('rtoFbxOrderPage.checkStatusTips'))
			}
			
			this.clientOperation = 'edit';
			this.skuInfoPopup = true;
			this.getCartonInfo(this.selectionRow);
		},
		// 批量操作 -- 不合格产品
		handleBatchFailOperation(){
			if(this.failSelectionRow.length == 0){
				return this.$message.warning(this.$t('tipsInfo.selectCartonTips'))
			}
			// 判断是否都是等待指令产品
			let waitList = this.failSelectionRow.filter(item => item.qcFailInstructionStatus == '');
			if(waitList.length > 0){
				return this.$message.warning(this.$t('rtoFbxOrderPage.checkStatusTips'))
			}
			this.clientOperation = 'edit';
			this.failSkuInfoPopup = true;
			this.getCartonInfo(this.failSelectionRow);
		},
		// 获取解析SKU信息
		getCartonInfo(data){
			let arr = [];
			data.forEach((val,valIndex) => {
				if(val.productList.length > 0 ){
					val.productList.forEach((item,index) => {
						let obj = {
						  customerBatchNo:val.fbxAwbNo,
						  span:0,
							type:'',
							normalProductAction:'',
							damagedPackagingAction:'',
							productDamageAction:'',
							skuDamagedAction:'',
							labelUrl:'',
							cartonId:val.id,
							isError:false,
						}
						if(index == 0){
							obj.span = val.productList.length;
						}
						item.damagedPackagingCount = item.damagedPackagingCount == 0?'':item.damagedPackagingCount;
						item.productCount = item.productCount == 0?'':item.productCount;
						item.productDamageCount = item.productDamageCount == 0?'':item.productDamageCount;
						item.skuDamagedCount = item.skuDamagedCount == 0?'':item.skuDamagedCount;
						obj = Object.assign({},obj,item);
						arr.push(obj);
					})
				}
			})
			this.cartonSkuDetail = arr;
		},
		// 合并行
		objectSpanMethod({ row, column, rowIndex, columnIndex }){
		  // 只有一箱数据
		  if(columnIndex == 0){
		    return {
		      rowspan: row.span,
		      colspan: 1,
		    }
		  }else{
		    return {
		      rowspan: 1,
		      colspan: 1,
		    }
		  }
		},
		
		// 点击复制箱号列信息
		handleCopyAwbNo(name){
			let str = '';
			this.dataList.forEach(item => {
				str += item[name] + '\n'
			})
			this.copyData(str);
		},
		// 点击复制订单号列
		handleCopyOrderNo(name){
			let str = '';
			this.alDataList.forEach(item => {
				str += item[name] + '\n'
			})
			this.copyData(str);
		},
		// 复制内容至剪切板
		copyData(data){
			this.$copyText(data).then( (e) => {
			  this.$message.success(this.$t('tipsInfo.copySuccess'))
			}, (e) => {
			  this.$message.error(this.$t('tipsInfo.copyFail'))
			})
		},
		
		
		// 是否存储时间到期
		// row 当前数据
		showStoreDayTips(row,type){//type 0合格 1不合格
		  let status = '';
			if(type == 0){
				status = row.normalProductInstructionStatus;
			}else{
				status = row.qcFailInstructionStatus;
			}
			if(status != ''){// 客户已操作
				return 100
			}else{// 未操作
			  if(row.crosscheckTime == '' || row.crosscheckTime == null){
					return 0;
				}else{
					// 转换时间格式，苹果浏览器和火狐new Date 转换时间格式为yyyy/mm/dd
					let rowDate = transformTime(row.crosscheckTime);
					let leaveDay = 0,
					    crossTime = new Date(rowDate),
							nowTime = new Date();
					let differTime = nowTime.getTime() - crossTime.getTime();
					leaveDay = parseInt(30 - differTime / (24 * 3600 * 1000));
					
					leaveDay = leaveDay < 0?0:leaveDay;
					
					return leaveDay;
				}
			}
			
		},
		// 获取订单状态
		returnActionStatus(row,type){// 0 合格列表，1不合格列表
			let statusName = '';
			if(row.actionResult == '' || row.actionResult == null){
				statusName = this.$t('commonInfo.waitForOrder');
			}else if(row.actionResult == '100'){
				switch(row.actionTypeStatus){
					case '1':statusName = this.$t('commonInfo.destory') + this.$t('commonInfo.processing');break;
					case '2':statusName = this.$t('rtoFbxOrderPage.crossCheck') + this.$t('commonInfo.processing');break;
					case '3':statusName = this.$t('rtoFbxOrderPage.transferWh') + this.$t('commonInfo.processing');break;
				}
			}else if(row.actionResult == '300'){
				switch(row.actionTypeStatus){
					case '1':statusName = this.$t('commonInfo.destory') + this.$t('commonInfo.completed');break;
					case '2':statusName = this.$t('rtoFbxOrderPage.crossCheckCompleted');break;
					case '3':statusName = this.$t('rtoFbxOrderPage.transferWh') + this.$t('commonInfo.completed');break;
					case null:statusName = this.$t('commonInfo.waitForOrder');break;// 这种情况为仓库已默认执行开箱验货，但是客户还没下达指令
				}
			}
			// 开箱验货后续的操作状态
			let status = type == '0'?row.normalProductInstructionStatus:row.qcFailInstructionStatus;
			if(status == '400'){
				statusName = this.$t('rtoFbxOrderPage.crossCheckNewTask');;
			}
			if(status == '500'){
				statusName = this.$t('rtoFbxOrderPage.crossCheckAllCompleted');;
			}
			return statusName
		},
		// 是否显示暂无信息
		returnHasAction(row){
			let isTrue = false;
			if(row.actionTypeStatus != '2'){
				if(row.actionResult == 300){
					isTrue = true;
				}
			}else{
				if(row.actionResult == null || row.actionResult == '' || row.actionResult == '100'){
					isTrue = true;
				}
			}
			return isTrue;
		},
		// 开箱清单
		handleSkuInfo(row,type){// type 0 合格 1、不合格
			let arr = [];
			if(type == '0'){
				if(this.showStoreDayTips(row,type) == 0){// 超时
					this.clientOperation = 'check';
				}else{
					if(row.normalProductInstructionStatus == ''){// 下达指令
						this.clientOperation = 'edit';
					}else{// 查看清单
						this.clientOperation = 'check';
					}
				}
				this.skuInfoPopup = true;
			}else if(type == '1'){
				if(this.showStoreDayTips(row,type) == 0){// 超时
					this.clientOperation = 'check';
				}else{
					if(row.qcFailInstructionStatus == ''){// 下达指令
						this.clientOperation = 'edit';
					}else{// 查看清单
						this.clientOperation = 'check';
					}
				}
				console.log(this.clientOperation);
				this.failSkuInfoPopup = true;
			}
			arr.push(row);
			this.getCartonInfo(arr);
		},
		// 查看图片
		handleViewImg(img){
			this.imgList = [];
			if(img.indexOf(',') == -1){
			  this.imgList.push(img)
			}else{
			  this.imgList = img.split(',');
			}
			this.showImg = true;
		},
		//关闭图片
		closeImg() {
			this.showImg = false;
		},
		// 返回操作状态所对应的值
		returnTypeValue(type){
			let typeValue = '';
			switch(type){	
				case this.$t('commonInfo.destory'): typeValue = '200'; break;
				case this.$t('commonInfo.inventory'): typeValue = '300'; break;
			}
			return typeValue;
		},
		//改变表格颜色
		cellStyle({row,column,rowIndex,columnIndex}){
			if(row['isError'] == true){
				return {
					background:'#e1cf97'
				}
			}
		},
		// 合格产品全部上架
		handleStockAllQcPassed(){
			this.cartonSkuDetail.forEach((item,index) => {
				item.normalProductAction = this.$t('commonInfo.inventory');
				this.$set(this.cartonSkuDetail,item,index);
			})
		},
		// 合格产品全部销毁
		handleDestoryAllQcPassed(){
			this.cartonSkuDetail.forEach((item,index) => {
				item.normalProductAction = this.$t('commonInfo.destory');
				this.$set(this.cartonSkuDetail,item,index);
			})
		},
		
		//关闭窗口
		handleSkuDialogClose(){
			this.skuInfoPopup = false;
			this.failSkuInfoPopup = false;
			this.cartonSkuDetail = [];
			this.clientOperation = 'check';
		},
		// 提交请求
		async confirmFBX(type){
			let FbxParams = {};
			if(type == '3'){//转仓
				FbxParams.deliveryWarehouse = this.transferInfo.deliveryWarehouse;
				FbxParams.deliveryAddress = this.transferInfo.deliveryAddress;
				FbxParams.contacts = this.transferInfo.contacts;
				FbxParams.contactsTel = this.transferInfo.contactsTel;
			}	
			FbxParams.fbxAwbNo = this.fbxOrderNo;
			FbxParams.actionTypeStatus = type;//1,销毁，2,开箱验货，3，转仓
			this.confirmLoading = true;
			const res = await this.$http.post("/toborder/customer/rto/fbx/operation",FbxParams);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.confirmLoading = false;
				this.getList();
			} else {
				this.confirmLoading = false;
			  this.$message.error(res.msg);
			}
		},
		
		// 第二个Tab页，显示不合格数据 2023-6-21 zpy
		// 分页条数
		handleFailSizeChange(size) {
		  this.failParams.limit = size;
		  this.failParams.page = 1;
		  this.getFailList();
		},
		// 分页页数
		handleFailCurrentChange(page) {
		  this.failParams.page = page;
		  this.getFailList();
		},
		// 搜索
		handleQueryFail(){
			this.failParams.page = 1;
			if(this.failAwbNo != null && this.failAwbNo != ''){
				this.failParams.awbNoList = this.strToArr(this.failAwbNo);
			}else{
				this.failParams.awbNoList = [];
			}
			if(this.qcFailedCrossTime != null && this.qcFailedCrossTime != ''){
				this.failParams.crosscheckTimeStart = this.qcFailedCrossTime[0];
				this.failParams.crosscheckTimeEnd = this.qcFailedCrossTime[1];
			}else{
				this.failParams.crosscheckTimeStart = '';
				this.failParams.crosscheckTimeEnd = '';
			}
			
			if(this.qcFailedInstructionTime != null && this.qcFailedInstructionTime != ''){
				this.failParams.instructionTimeStart = this.qcFailedInstructionTime[0];
				this.failParams.instructionTimeEnd = this.qcFailedInstructionTime[1];
			}else{
				this.failParams.instructionTimeStart = '';
				this.failParams.instructionTimeEnd = '';
			}
			if(this.qcFailedSKU != ''){
				this.failParams.productCodeList = this.strToArr(this.qcFailedSKU);
			}else{
				this.failParams.productCodeList = [];
			}
			this.getFailList();
		},
		// 重置
		handleResetFail(){
			this.qcFailedInstructionTime = null;
			this.qcFailedCrossTime = null;
			this.failAwbNo = '';
			this.qcFailedSKU = '';
			this.failParams = {
				page:1,
				limit:10,
				awbNoList:[],//箱号集合
				productQcType: '1',// 0合格商品，1不合格商品
				instructionStatus:'',//指令状态，300待处理 400处理中 500已完成
				crosscheckTimeStart:'',//开箱开始时间
				crosscheckTimeEnd:'',//开箱结束时间
				instructionTimeStart:'',//指令开始时间
				instructionTimeEnd:'',//指令结束时间
				productCodeList:[],
			}
			this.getFailList();
		},
		
		// 获取列表
		async getFailList() {
			this.loading = true;
			this.failDataList = [];
			
			const res = await this.$http.post("/toborder/customer/rto/fbx/newList", this.failParams);
			if (res.code === 200) {
				this.qcFailedCount = 0;
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.failTotal = res.data.totalCount;
				//去除勾选
				this.failIds = [];
				this.failSelectionRow = [];
				this.$refs.failedTableList.clearSelection();
				data.forEach(item => {
					item.crosscheckTime = formatDate(item.crosscheckTime);
					if(item.actionTypeStatusName == '' || item.actionTypeStatusName == null){
						item.actionTypeStatusName = this.$t('tipsInfo.noData');
					}
					// 返回每个SKU信息列表 列表返回数据同一个SKU包含合格和不合格的数据，需要分离方便循环显示
					item.returnList = [];
					// 判断该数据SKU是否有损坏不合格SKU，用于列表上批量上架操作，有损坏物品不能批量上架
					item.hasQcFailedSku = false;
					if(item.productList.length > 0){
						item.productList.forEach(subItem => {
							let failedCount = 0;
							if(subItem.skuDamagedCount > 0){
								failedCount = subItem.skuDamagedCount;
							}
							if(subItem.damagedPackagingCount > 0){
								failedCount = subItem.damagedPackagingCount;
							}
							if(subItem.productCount > 0){
								failedCount = subItem.productCount;
							}
							// 无SKU箱贴显示Fleetan SKU
							item.returnList.push({
								sku:subItem.clientProductCode,
								count:failedCount,
								type:'failed',
								id:subItem.id + 'failed',
							})
						})
					}else{//没有数据则不能批量上架
						item.hasQcFailedSku = true;
					}
					// 获取待处理数量
					if(item.qcFailInstructionStatus == ''){
						this.qcFailedCount ++;
					}
				})
				
			  this.loading = false;
			  this.failDataList = data;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		// 获取唯一标识
		getFailRowKey(row){
		  return row.id;
		},
		//监听勾选
		changeFailSelection(selection) {
			this.failIds = selection.map(item => item.id);
		  this.failSelectionRow = selection;
		},
		// 批量销毁
		handleDestoryAllQcFailed(type){ // 1、 换标，2、包装损坏，3、产品损坏
			this.cartonSkuDetail.forEach((item,index) => {
				switch(type){
					case '1': 
					if(item.skuDamagedCount > 0){
						item.skuDamagedAction = this.$t('commonInfo.destory');
					}
					break;
					case '2': 
					if(item.damagedPackagingCount > 0){
						item.damagedPackagingAction = this.$t('commonInfo.destory');
					}
					break;
					case '3': 
					if(item.productDamageCount > 0){
						item.productDamageAction = this.$t('commonInfo.destory');
					}
					break;
				}
				this.$set(this.cartonSkuDetail,item,index);
			})
			
		},
		// 批量换标
		handleChangeLabelAllQcFailed(){
			this.cartonSkuDetail.forEach((item,index) => {
				if(item.skuDamagedCount > 0){
					item.skuDamagedAction = this.$t('rtoFbxOrderPage.changeSku');
				}
				this.$set(this.cartonSkuDetail,item,index);
			})
		},
		// 批量换原包装
		handleChangeAllPackage(){
			this.cartonSkuDetail.forEach((item,index) => {
				if(item.damagedPackagingCount > 0){
					item.damagedPackagingAction = this.$t('rtoFbxOrderPage.changeOrigin');
				}
				this.$set(this.cartonSkuDetail,item,index);
			})
		},
		// 批量换OP袋
		handleChangeAllOp(){
			this.cartonSkuDetail.forEach((item,index) => {
				if(item.damagedPackagingCount > 0){
					item.damagedPackagingAction = this.$t('rtoFbxOrderPage.changePO');
				}
				this.$set(this.cartonSkuDetail,item,index);
			})
		},
		// 是否展示SKU标签上传按钮
		returnShowSKUUploadBtn(row){
			let isShow = false;
			if(row.skuDamagedAction == this.$t('rtoFbxOrderPage.changeSku')){
				isShow = true;
			}
			if(row.damagedPackagingAction == this.$t('rtoFbxOrderPage.changeOrigin') || row.damagedPackagingAction == this.$t('rtoFbxOrderPage.changePO')){
				isShow = true;
			}
			if(this.clientOperation == 'check'){
				isShow = false;
			}
			return isShow;
		},
		// 上传SKU标签
		uploadQcFailedLabel(data){
			console.log(data);
			let index = this.cartonSkuDetail.findIndex(item => item.id + item.clientProductCode == data.string)
			this.cartonSkuDetail[index].labelUrl = data.url;
		},
		// 下载SKU标签
		handleDownloadLabel(url){
			window.open(url);
		},
		// 检测用户指令是否完全
		checkProductList(type){
			let isAllRight = true;
			this.cartonSkuDetail.forEach((item,index) => {
				item.isError = false;
				if(type == '0'){// 合格产品判断字段是否选择
					if(item.normalProductAction == '100'){
						isAllRight = false;
						item.isError = true;
					}
				}else{ // 不合格产品判断
				  // 判断是否需要上传标签文件
					let isUploadLabel = false;
					if(item.skuDamagedCount > 0){// 标签损坏
					  if(item.skuDamagedAction == '100'){
							isAllRight = false;
							item.isError = true;
						} else if(item.skuDamagedAction == this.$t('rtoFbxOrderPage.changeSku')){
							isUploadLabel = true;
						}
					}
					if(item.damagedPackagingCount > 0){//包装损坏
					  if(item.damagedPackagingAction == '100'){
							isAllRight = false;
							item.isError = true;
						}else if(item.damagedPackagingAction == this.$t('rtoFbxOrderPage.changeOrigin') || item.damagedPackagingAction == this.$t('rtoFbxOrderPage.changePO')){
							isUploadLabel = true;
						}
					}
					if(item.productDamageCount > 0 && item.productDamageAction == '100'){// 产品损坏
						isAllRight = false;
						item.isError = true;
					}
					
					if(isUploadLabel && item.labelUrl == ''){
						isAllRight = false;
						item.isError = true;
					}
				}
				
				this.$set(this.cartonSkuDetail,index,item)
			})
			return isAllRight;
		},
		
		// 提交用户指令
		handleSubmitClientAction(type){ // type 0、合格产品提交，1、不合格产品提交
			// 校验用户指令是否完整，必须全部都下达指令
			if(!this.checkProductList(type)){
				let message = type == '0'?this.$t('rtoFbxOrderPage.submitQcPassedDataTips'):this.$t('rtoFbxOrderPage.submitQcFailedDataTips');
				return this.$message.warning(message);
			}
			let form = [];
			// 获取箱ID 
			let arr = this.cartonSkuDetail.map(item => item.cartonId);
			arr = Array.from(new Set(arr));
			
			arr.forEach(val => {
				let obj = {
					id:val,
					productList:[],
				}
				this.cartonSkuDetail.forEach(item => {
					if(item.cartonId == val){
						if(type == '0'){//合格
							obj.productList.push({
								clientProductCode:item.clientProductCode,
								normalProductAction:this.returnActionType(item.normalProductAction),
							})
						}else{//不合格
							obj.productList.push({
								clientProductCode:item.clientProductCode,
								skuDamagedAction:this.returnActionType(item.skuDamagedAction),
								productDamageAction:this.returnActionType(item.productDamageAction),
								damagedPackagingAction:this.returnActionType(item.damagedPackagingAction),
								newSkuPicUrl:item.labelUrl,
							})
						}
					}
				})
				form.push(obj);
			})
			this.submitCartonClientActionData(form,type);
		},
		// 货物处理方式
		returnActionType(type){
			let val = '';
			switch(type){
				case this.$t('commonInfo.destory'): val = 200; break;
				case this.$t('commonInfo.inventory'): val = 300; break;
				case this.$t('rtoFbxOrderPage.changeOrigin'): val = 400; break;
				case this.$t('rtoFbxOrderPage.changePO'): val = 500; break;
				case this.$t('rtoFbxOrderPage.changeSku'): val = 600; break;
			}
			return val;
		},
		// 提交商品客户指令数据
	  async	submitCartonClientActionData(from,type){
			let data = {
				productQcType:type,
				rtoTaskList:from,
			}
			this.submitLoading = true;
			const res = await this.$http.post("/toborder/customer/rto/fbx/clientAction", data);
			if (res.code === 200) {
			  this.$message.success(this.$t('tipsInfo.operationSuccess'));
				this.submitLoading = false;
				this.handleSkuDialogClose();
				if(type == '0'){
					this.getList();
				}else{
					this.getFailList();
				}
				
			} else {
			  this.submitLoading = false;
			  this.$message.error(res.msg);
			}
		},
		
		
		// 第三个tab页 显示已完成列表 2022-5-31 zpy
		// 搜索
		handleAlQuery(){
			this.alreadyQuery.page = 1;
			if (this.alAwbNoArr != "") {
				this.alreadyQuery.awbNos = this.strToArr(this.alAwbNoArr);
			} else {
			  this.alreadyQuery.awbNos = [];
			}
			this.getAlList();
		},
		// 获取列表
		async getAlList(){
			this.alLoading = true;
			this.alDataList = [];
			const res = await this.$http.post("/toborder/customer/rto/fbx/list", this.alreadyQuery);
			
			if (res.code === 200) {
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.alTotal = res.data.totalCount;
			  this.alLoading = false;
				data.forEach(item => {
					item.crosscheckTime = formatDate(item.crosscheckTime);
				})
			  this.alDataList = data;
			} else {
			  this.alLoading = false;
			  this.$message.error(res.msg);
			}
		},
		
  },
  created() {
		let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
		let userInfo = JSON.parse(window.sessionStorage.getItem("params"));
		this.customerName = userInfo.username;
		this.getList();
		// 获取不合格数量
		this.getFailList();
	},
  mounted() {}
};
</script>
<style scoped>
	.app-container{
		padding: 10px;
	}
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
	.qcPassSkuItem{
		color: #0077AA;
	}
	.qcFailedSkuItem{
		color: #d8431d;
	}
	.qcFailTable /deep/ .el-radio-group .el-radio-button__inner{
		width: 80px;
		border-radius: 4px;
		border-left: 1px solid #DCDFE6;
		box-shadow: none;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>