<template>
    <el-upload
      action="https://fleetan-hk-bucket.oss-accelerate.aliyuncs.com"
      :data="dataObj"
			list-type="text"
			:multiple="false"
			:file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
			accept=".jpg, .png"
    >
      <el-button size="small" type="primary">{{btnName}}</el-button>
    </el-upload>
</template>
<script>
import { policy } from "./policy";
import { createUniqueString } from "@/utils/index";
export default {
  name: "singleUploadWithParams",
  props: {
    tipText:{
    	type: String,
    	default:function(){
    		return '上传文件'
    	}
    },
    stringParams:{
    	type: String,
    	default:function(){
    		return ''
    	}
    },
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
        uuid: ""
      },
      btnName:'上传文件',
			fileList:[],
    };
  },
	watch:{
		//监听按钮名称和提示文字
		'tipText':{
			handler(newValue){
				this.btnName = newValue;
			},
			immediate: true,
		},
	},
  computed: {
  },
  mounted() {},
  methods: {
    emitInput(url) {
      // 回传参数
      let data = {
        url:url,
        string:this.stringParams,
      }
      this.$emit("input", data);
    },
		handleRemove(file, fileList) {
			this.fileList = fileList;
			this.emitInput(this.fileList);
		},
		handlePreview(file) {
			window.open(file.url, "_blank");
		},
    beforeUpload(file) {
      let _self = this;
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
      return new Promise((resolve, reject) => {
        policy()
          .then(response => {
						let ramdomValue = createUniqueString();
            _self.dataObj.policy = response.policy;
            _self.dataObj.signature = response.signature;
            _self.dataObj.ossaccessKeyId = response.accessid;
            _self.dataObj.key =
              response.dir + ramdomValue + "~${filename}";
            _self.dataObj.dir = response.dir;
            _self.dataObj.host = response.host;

            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    handleUploadSuccess(res, file,fileList) {
			this.$message.success("上传成功...");
			this.fileList = [];
			let fileName = encodeURIComponent(file.name);
			let url = this.dataObj.host + "/" + this.dataObj.key.replace("${filename}", fileName)
			this.fileList.push({
				name:fileName,
				url:url
			})
			this.emitInput(url);
    },
  }
};
</script>
<style>
</style>
