import request from '@/utils/request'
export function policy() {



    // return request({
    //     url: '/oss/policy',
    //     method: 'get'
    // }).then(({ data }) => {

    // })

    return new Promise((resolve, reject) => {
        request({
            url: '/oss/policy',
            method: 'get',
            // params: query
        }).then(({ data }) => {
            resolve(data);
        })
    });

}